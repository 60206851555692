<template>
  <div class="setting_form">

    <b-modal modal-class="modal_basic changeEmailModal" id="changeEmailModal" hide-footer hide-header>
      <img @click="$bvModal.hide('changeEmailModal')" class="close_modal" src="/assets/img/cross.svg" alt="">

      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            Change your account's email address
          </h3>
        </div>

        <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">New Email Address</div>
            <div class="name">
              <div class="validation_input">
                <input type="email" placeholder="Enter new email address" v-model="newEmail">
              </div>
            </div>
          </div>
        </div>

        <div class=" mt-3 mb-3  input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">Confirm New Email Address</div>
            <div class="name">
              <div class="validation_input">
                <input type="email" placeholder="Enter confirm new email address"
                       v-model="newConfirmEmail">
              </div>
            </div>
          </div>
        </div>

        <div class="  alert-box  danger" v-if="notAvailableError">
          <div class="inner_box">
            <p class="mt-0">The email <strong>{{ notAvailableEmail }}</strong> you've entered is already in
              use, please try something else.</p>
          </div>
        </div>
        <div class="alert-box  danger" v-else-if="(newEmail.trim()).length > 0 && (newConfirmEmail.trim()).length > 0
                                && newEmail.trim() != newConfirmEmail.trim()">
          <div class="inner_box">
            <p class="mt-0">The confirm email you've entered doesn't match.</p>
          </div>
        </div>

        <div class=" btn_block text-right">

          <button v-if="getUserLoader.changeEmailLoader"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Processing...</span>
            <clip-loader :size="size" :color="color"></clip-loader>
          </button>

          <button v-else @click.prevent="updateEmail()"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
            <span>Update</span>
          </button>

          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="$bvModal.hide('changeEmailModal')"><span>Close</span></button>

        </div>

      </div>
    </b-modal>

    <div class="inline-box d-flex align-items-start">
      <div class="white_box pr-2 ">
        <div class="box_inner">
          <div class="box_head d-flex align-items-center">
            <div class="left">
              <h2>Basic Details</h2>

            </div>
            <!--<div class="right ml-auto">-->

            <!--</div>-->

          </div>
          <div class="box_content">
            <form class="basic_form clear">

              <div class="profile_detail">
                <div class="d-flex align-items-center">
                  <div class=" input_field p-4">
                    <div class="profile_image"
                         :style="{ 'background': 'url(' + backgroundImage(getProfile) + ')' }">

                      <div class="input_file" v-if="!image_loader">
                        <input @change="uploadProfilePicture" type="file" class="profile_input"
                               id="profile_input" data-cy="p-image">
                        <label for="profile_input">
                          <i v-tooltip.top-center="'Change Image'" class="far fa-camera"></i>
                        </label>

                        <!--to remove image-->
                        <input type="text" class="profile_input"
                               v-if="getProfile.profile_image"
                               @click="removeProfilePicture"
                               id="remove_image_input">
                        <label v-if="getProfile.profile_image" for="remove_image_input" class="remove_image">
                          <i v-tooltip.top-center="'Remove Image'" class="far fa-close"></i>
                        </label>
                      </div>
                      <div class="input_file bg_opacity" v-else>
                        <clip-loader :size="size" :color="color"></clip-loader>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <div class=" input_icon_text d-flex align-items-center profile__box">

                      <div class="avatar">
                        <i class="fal fa-user"></i>
                      </div>
                      <div class="content">
                        <div class="url">First Name</div>
                        <div class="name">
                          <div class="validation_input">
                            <input placeholder="Enter your first name..."
                                   v-model="first_name" type="text" maxlength="100" data-cy="first-name">

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                      <div class="avatar">
                        <i class="fal fa-user"></i>
                      </div>
                      <div class="content">
                        <div class="url">Last Name</div>
                        <div class="name">
                          <div class="validation_input">
                            <input placeholder="Enter your last name..." v-model="last_name"
                                   type="text" maxlength="100" data-cy="last-name">

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 input_icon_text d-flex align-items-center profile__box">

                      <div class="avatar">
                        <i class="fal fa-envelope"></i>
                      </div>

                      <div class="content">
                        <div class="url">Email Address</div>
                        <div class="name">
                          <div class="validation_input">
                            <input class="bg-white" placeholder="Enter your email address..." v-model="email"
                                   disabled="true" type="email">

                          </div>
                        </div>
                      </div>

                      <div class="avatar ml-2 mr-0">

                        <i style="color: #313a51;" class="far fa-pencil cursor_pointer"
                           v-tooltip.top-center="'Change Email'"
                           @click.prevent="changeEmail()"></i>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="  mt-3   text-center">

                  <button @click.prevent="saveProfile()"
                          class="btn---cta btn-blue with-shadow btn-round btn-bold">
                    <span>Save</span>
                  </button>

                </div>
                <div class="  mt-3  " v-if="getProfile.change_email_token">
                  <div class="alert-box  warning">
                    <div class="inner_box">
                      <p class="">Nice! We’ve sent a confirmation email to: <strong>
                        {{ getProfile.change_email }}</strong>
                        <br>
                        To complete your email address change, use the link inside your
                        confirmation email.</p>
                      <button class="btn---cta btn-blue with-shadow btn-round btn-bold mt-2 mr-2"
                              @click.prevent="resendChangeEmailRequest()"><span>Resend
                        Email</span>
                      </button>
                      <button class="btn---cta btn-border color--gray btn-round  btn-bold mt-2"
                              @click.prevent="cancelChangeEmailRequest()"><span>Cancel Request</span>
                      </button>

                    </div>

                  </div>
                </div>
              </div>

            </form>

          </div>

        </div>
      </div>
      <div class="white_box pl-2">
        <div class="box_inner">
          <div class="box_head d-flex align-items-center">
            <div class="left">
              <h2>Change Password</h2>

            </div>
            <!--<div class="right ml-auto">-->

            <!--</div>-->
          </div>
          <div class="box_content">
            <form class="basic_form clear">
              <div class="password_detail  ">

                <div class="input_icon_text d-flex align-items-center profile__box"
                     :class="{'input-field-error': validations.current_password}">

                  <div class="avatar">
                    <i class="fal fa-lock"></i>
                  </div>
                  <div class="content">
                    <div class="url">Current Password</div>
                    <div class="name">
                      <div class="validation_input">
                        <input placeholder="Enter your current password..."
                               v-model="current_password" type="password" data-cy="c-password">

                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="validations.current_password" class="input-error">
                                    {{ messages.current_password }}
                                </span>

                <div class="mt-3 input_icon_text d-flex align-items-center profile__box"
                     :class="{'input-field-error': validations.new_password}">

                  <div class="avatar">
                    <i class="fal fa-lock"></i>
                  </div>
                  <div class="content">
                    <div class="url">New Password</div>
                    <div class="name">
                      <div class="validation_input">
                        <input placeholder="Enter your new password..." v-model="new_password"
                               type="password" data-cy="new-password">

                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="validations.new_password" class="input-error">
                                    {{ messages.new_password }}
                                </span>

                <div class=" mt-3 input_icon_text d-flex align-items-center profile__box"
                     :class="{'input-field-error': validations.confirm_new_password}">

                  <div class="avatar">
                    <i class="fal fa-lock"></i>
                  </div>
                  <div class="content">
                    <div class="url">Confirm New Password</div>
                    <div class="name">
                      <div class="validation_input">
                        <input placeholder="Enter confirm password..."
                               v-model="confirm_new_password" type="password" data-cy="c-new-password">

                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="validations.confirm_new_password" class="input-error">
                                    {{ messages.confirm_new_password }}
                                </span>

                <div class="  mt-3   text-center">

                  <button @click.prevent="updatePassword()"
                          class="btn---cta btn-blue with-shadow btn-round btn-bold">
                    <span>Update Password</span>
                  </button>

                </div>

              </div>

            </form>

          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {removeProfilePictureURL, saveProfileURL, updatePasswordURL, uploadProfilePictureURL} from '@/config/config.js'
import {mapActions, mapGetters} from 'vuex'
import {cancelChangeEmailURL, changeEmailURL, resendChangeEmailURL} from '@/config/urls/user/user.js'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import http from '@/mixins/http-lib'
import {deleteDomainURL} from '@/config/api'

export default ({

  data: function () {
    return {
      first_name: '',
      last_name: '',
      email: '',
      newEmail: '',
      newConfirmEmail: '',
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      notAvailableError: false,
      notAvailableEmail: '',

      new_password: null,
      confirm_new_password: null,
      current_password: null,
      validations: {
        current_password: false,
        new_password: false,
        confirm_new_password: false
      },
      messages: {
        current_password: 'Please enter your current password.',
        new_password: 'Please enter your new password.',
        confirm_new_password: 'Please confirm your new password.'
      },
      image_loader: false
    }
  },

  created () {
    this.getSiteDetails
    this.email = this.getProfile.email
    this.first_name = this.getProfile.first_name
    this.last_name = this.getProfile.last_name
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserLoader'])
  },
  methods: {
    ...mapActions(['setProfileImage', 'setChangeEmailLoader']),

    saveProfile () {
      http.post(saveProfileURL, {
        'first_name': this.first_name,
        'last_name': this.last_name
      }).then(response => {
        if (response.data.tokenError && response.data.tokenError == true) {
          this.alertMessage(response.data.message, 'error')
        } else {
          if (response.data.status) {
            this.getProfile.first_name = this.first_name
            this.getProfile.last_name = this.last_name
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        }
      }, response => {

      })
    },

    uploadProfilePicture (e) {
      this.image_loader = true
      console.debug('picture upload')
      e.preventDefault()
      let files = e.target.files || e.dataTransfer.files
      let formData = new FormData()
      formData.append('profile_picture', files[0])

      http.post(uploadProfilePictureURL, formData).then(
        response => {
          if (response.data.tokenError && response.data.tokenError == true) {
            this.alertMessage(response.data.message, 'error')
          } else {
            if (response.data.status) {
              this.setProfileImage(response.data.url)
              this.alertMessage(response.data.message, 'success')
            } else {
              this.alertMessage(response.data.message, 'error')
            }
          }
          this.image_loader = false
        },
        response => {

        }
      )
    },
    removeProfilePicture (e) {
      var self = this;
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Delete it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (isConfirm) {
            http.post(removeProfilePictureURL, {}).then(
              response => {
                if (response.data.tokenError && response.data.tokenError == true) {
                  self.alertMessage(response.data.message, 'error')
                } else {
                  if (response.data.status) {
                    self.setProfileImage(response.data.url)
                    self.alertMessage(response.data.message, 'success')
                  } else {
                    self.alertMessage(response.data.message, 'error')
                  }
                }
              }
            )
          } else {
            swal()
          }
        })

    },

    changeEmail () {
      this.newEmail = ''
      this.newConfirmEmail = ''
      this.notAvailableError = false
      this.notAvailableEmail = ''
      // $('#changeEmailModal').modal('show')
      this.$bvModal.show('changeEmailModal')
    },

    updateEmail () {
      // change message text here
      if ((this.newEmail.trim()).length == 0) {
        this.alertMessage('Please enter new Email Address', 'error')
      } else if ((this.newConfirmEmail.trim()).length == 0) {
        this.alertMessage('Please enter confirm new Email Address', 'error')
      } else if (this.newEmail.trim() != this.newConfirmEmail.trim()) {
        this.alertMessage('New Email Address and confirm new Email Address do not match', 'error')
      } else {
        this.notAvailableError = false
        this.notAvailableEmail = ''
        this.setChangeEmailLoader(true)

        http.post(changeEmailURL, {
          'email': this.newEmail
        }).then(response => {
          if (response.data.tokenError && response.data.tokenError == true) {
            this.alertMessage(response.data.message, 'error')
          } else {
            if (response.data.status) {
              this.alertMessage(response.data.message, 'success')
              this.getUserProfile()
              // $('#changeEmailModal').modal('hide')
              this.$bvModal.hide('changeEmailModal')
            } else {
              if (response.data.notAvailable) {
                this.notAvailableError = true
                this.notAvailableEmail = this.newEmail
              } else {
                this.alertMessage(response.data.message, 'error')
              }
            }
            this.setChangeEmailLoader(false)
          }
        }, response => {
          this.setChangeEmailLoader(false)
        })
      }
    },

    resendChangeEmailRequest () {
      http.post(resendChangeEmailURL).then(response => {
        if (response.data.tokenError && response.data.tokenError == true) {
          this.alertMessage(response.data.message, 'error')
        } else {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        }
      }, response => {
      })
    },
    cancelChangeEmailRequest () {
      http.post(cancelChangeEmailURL).then(response => {
        if (response.data.tokenError && response.data.tokenError == true) {
          this.alertMessage(response.data.message, 'error')
        } else {
          if (response.data.status) {
            this.getUserProfile()
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        }
      }, response => {
      })
    },

    // password detail
    updatePassword () {
      this.validations.new_password = this.requiredCheck(this.new_password)
      this.validations.confirm_new_password = this.requiredCheck(this.confirm_new_password)
      this.validations.current_password = this.requiredCheck(this.current_password)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        // new password and confirm new password verification
        if (this.new_password !== this.confirm_new_password) {
          this.alertMessage('Your new password and confirm new password does not match.', 'error')
          return
        }

        // new password equals to the current passsword
        if (this.new_password === this.current_password) {
          this.alertMessage('Your new password cannot be the existing current password.', 'error')
          return
        }

        http.post(updatePasswordURL, {
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
          current_password: this.current_password
        }).then(res => {
          if (res.data.tokenError && res.data.tokenError == true) {
            this.alertMessage(res.data.message, 'error')
          } else {
            if (res.data.status) {
              this.alertMessage(res.data.message, 'success')
            } else {
              this.alertMessage(res.data.message, 'error')
            }
          }
        }, err => {

        })
      }
    }
  },
  watch: {
    newEmail () {
      this.notAvailableError = false
      this.notAvailableEmail = ''
    },
    newConfirmEmail () {
      this.notAvailableError = false
      this.notAvailableEmail = ''
    },
    new_password (value) {
      if (value && value.trim().length > 0) this.validations.new_password = false
    },
    current_password (value) {
      if (value && value.trim().length > 0) this.validations.current_password = false
    },
    confirm_new_password (value) {
      if (value && value.trim().length > 0) this.validations.confirm_new_password = false
    }
  }

})
</script>

<style lang="less" scoped>
.bg_opacity {
  background: rgba(0, 0, 0, 0.51) !important;
}
</style>
